import { render, staticRenderFns } from "./blindActivity.vue?vue&type=template&id=0b496ab4&scoped=true&"
import script from "./blindActivity.vue?vue&type=script&lang=js&"
export * from "./blindActivity.vue?vue&type=script&lang=js&"
import style0 from "./blindActivity.vue?vue&type=style&index=0&id=0b496ab4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b496ab4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/mc/git/dq-admin-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0b496ab4')) {
      api.createRecord('0b496ab4', component.options)
    } else {
      api.reload('0b496ab4', component.options)
    }
    module.hot.accept("./blindActivity.vue?vue&type=template&id=0b496ab4&scoped=true&", function () {
      api.rerender('0b496ab4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/cmsPage/dqPlatform/blindActivity.vue"
export default component.exports